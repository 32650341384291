export class ProjectAgentesDb
{
 
    public static agentes = { 
        '584142788259'     : {
            'title': 'Agentes On-Line',
            'table': {
                'columns': ['avatar', 'name', 'phone', 'cola', 'online', 'iconReasig'],
                'type': ['avatar', 'string', 'string', 'string', 'icon', 'icon'],
                'rows'   : [
                    {
                        avatar  : 'assets/images/avatars/james.jpg',
                        name    : 'Jack Gilbert',
                        phone   : '+16 298 032 7774',
                        cola : '10/0',                                                
                        online  : 'adjust/green',  // icon y color
                        iconReasig: 'apps/black'
                    },
                    {
                        avatar  : 'assets/images/avatars/katherine.jpg',
                        name    : 'Kathy Anderson',
                        phone   : '+23 572 311 1136',
                        cola : '10/6',                                                
                        online  : 'adjust/green',
                        iconReasig: 'apps/black'                         
                    },
                    {
                        avatar  : 'assets/images/avatars/andrew.jpg',
                        name    : 'Mark Turner',                         
                        phone   : '+01 139 803 9263',
                        cola : '20/3',                                                
                        online  : 'adjust/green',
                        iconReasig: 'apps/black'   
                        
                    },
                    {
                        avatar  : 'assets/images/avatars/jane.jpg',
                        name    : 'Kathryn Martinez',                         
                        phone   : '+25 467 022 5393',
                        cola : '18/2',                                                
                        online  : 'adjust/green',
                        iconReasig: 'apps/black'        
                    },
                    {
                        avatar  : 'assets/images/avatars/alice.jpg',
                        name    : 'Annie Gonzales',                         
                        phone   : '+99 891 619 7138',
                        cola : '10/3',                                                
                        online  : 'adjust/green',
                        iconReasig: 'apps/black'        
                    },
                    {
                        avatar  : 'assets/images/avatars/vincent.jpg',
                        name    : 'Howard King',                         
                        phone   : '+46 984 348 1409',
                        cola : '10/7',                                                
                        online  : 'adjust/green',
                        iconReasig: 'apps/black'        
                    },
                    {
                        avatar  : 'assets/images/avatars/joyce.jpg',
                        name    : 'Elizabeth Dixon',                         
                        phone   : '+33 332 067 9063',
                        cola : '10/9',                                                
                        online  : 'adjust/red',
                        iconReasig: 'apps/black'       
                    },
                    {
                        avatar  : 'assets/images/avatars/danielle.jpg',
                        name    : 'Dorothy Morris',                         
                        phone   : '+05 490 958 6120',
                        cola : '0/7',                                                
                        online  : 'adjust/red',
                        iconReasig: 'apps/black'
                    },
                    {
                        avatar  : 'assets/images/avatars/carl.jpg',
                        name    : 'Mark Gonzales',
                        phone   : '+03 168 394 9935',
                        cola : '6/9',                                                
                        online  : 'adjust/red',
                        iconReasig: 'apps/black' 
                    },
                    {
                        avatar  : 'assets/images/avatars/profile.jpg',
                        name    : 'Catherine Rogers',                         
                        phone   : '+86 235 407 5373',
                        cola : '7/8',                                                
                        online  : 'adjust/red',
                        iconReasig: 'apps/black'       
                    }
                   
                ]
            }
        },
        '584141363700' : {
            'title': 'Agentes On-Line',
            'table': {
                'columns': ['avatar', 'name', 'phone', 'cola', 'online', 'iconReasig'],
                'type': ['avatar', 'string', 'string', 'icon', 'icon'],
                'rows'   : [
                    {
                        avatar  : 'assets/images/avatars/andrew.jpg',
                        name    : 'Mark Turner',                         
                        phone   : '+01 139 803 9263',
                        cola : '20/3',                                                
                        online  : true,
                        iconReasig: true   
                        
                    },
                    {
                        avatar  : 'assets/images/avatars/jane.jpg',
                        name    : 'Kathryn Martinez',                         
                        phone   : '+25 467 022 5393',
                        cola : '18/2',                                                
                        online  : false,
                        iconReasig: true        
                    },
                    {
                        avatar  : 'assets/images/avatars/alice.jpg',
                        name    : 'Annie Gonzales',                          
                        phone   : '+99 891 619 7138',
                        cola : '10/3',                                                
                        online  : true,
                        iconReasig: true        
                    }, 
                    {
                        avatar  : 'assets/images/avatars/james.jpg',
                        name    : 'Jhon Gilbert',
                        phone   : '+16 298 032 7774',
                        cola : '10/0',                                                
                        online  : true,
                        iconReasig: true
                    },
                    {
                        avatar  : 'assets/images/avatars/katherine.jpg',
                        name    : 'Kathy Anderson',
                        phone   : '+23 572 311 1136',
                        cola : '10/6',                                                
                        online  : true,
                        iconReasig: true                         
                    },
                    
                   
                ]
            }
        }
    };
}
