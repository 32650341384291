export class HorariosFakeDb
{
    // Si se edita alguno de los campos aqui presente sobre todo menú
    // asi sea una mayúscula puede dañar el sistema, NO TOCAR MEJOR
    public static Horarios = {  
        colum: ['descripcion', 'jornadas', 'horas', 'Status', 'menú'],
        type: ['string', 'string', 'string', 'boolean', 'menu'],
        TitleColumShow: ['Campo', 'Canal', 'Menú'],
        clientes:[
            {
                id:1,
                descripcion:"jornadas normal",
                jornadas:[
                    {
                        Dia:["Lun", "Mar", "Mie"],
                        Horas:[
                            {
                                Inicio:"07:11",
                                Fin:"11:11"
                            },
                            {
                                Inicio:"12:11",
                                Fin:"16:11"
                            }
                        ]
                    }, 
                    {
                        Dia:["Jue"],
                        Horas:[
                            {
                                Inicio:"08:11",
                                Fin:"12:11"
                            },
                            {
                                Inicio:"13:11",
                                Fin:"17:11"
                            }
                        ]
                    }
                ],
                Status:true
            },
            {
                id:2,
                descripcion:"desc2",
                jornadas:[
                    {
                        Dia:["Lun", "Mar", "Sab"],
                        Horas:[
                            {
                                Inicio:"07:11",
                                Fin:"11:11"
                            },
                            {
                                Inicio:"12:11",
                                Fin:"16:11"
                            }
                        ]
                    }, 
                    {
                        Dia:["Jue"],
                        Horas:[
                            {
                                Inicio:"08:11",
                                Fin:"12:11"
                            },
                            {
                                Inicio:"13:11",
                                Fin:"17:11"
                            }
                        ]
                    }
                ],
                Status:false
            }
        ]
    }
}