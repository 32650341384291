import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'dashboards',
                title    : 'Dashboards',
                translate: 'NAV.DASHBOARDS',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id   : 'project',
                        title: 'Control',
                        type : 'item',
                        url  : '/apps/dashboards/project'
                    },
                    {
                        id   : 'analytics',
                        title: 'Resumen',
                        type : 'item', 
                        url  : '/apps/dashboards/analytics'
                    }                    
                ]
            },
            {
                id       : 'Clientes',
                title    : 'Clientes',
                translate: 'NAV.CHAT',
                type     : 'item',
                icon     : 'person',
                url      : '/apps/e-commerce/products',
                badge    : {
                    title: '2',
                    bg   : '#09d261',
                    fg   : '#FFFFFF'
                } 
            },
            {
                id       : 'calendar',
                title    : 'Campaña',
                translate: 'NAV.CALENDAR',
                type     : 'item',
                icon     : 'today',
                url      : '/apps/calendar'
            },           
            {
                id       : 'chat',
                title    : 'Chat',
                translate: 'NAV.CHAT',
                type     : 'item',
                icon     : 'chat',
                url      : '/apps/chat',
                badge    : {
                    title: '13',
                    bg   : '#09d261',
                    fg   : '#FFFFFF'
                }
            },             
            {
                id       : 'to-do',
                title    : 'Tareas',
                translate: 'NAV.TODO',
                type     : 'item',
                icon     : 'check_box',
                url      : '/apps/todo',
                badge    : {
                    title: '3',
                    bg   : '#FF6F00',
                    fg   : '#FFFFFF'
                }
            },
            {
                id       : 'scrumboard',
                title    : 'Proyectos',
                translate: 'NAV.SCRUMBOARD',
                type     : 'item',
                icon     : 'assessment',
                url      : '/apps/scrumboard'
            },
            {
                id       : 'Reportes',
                title    : 'Reportes',
                translate: 'NAV.SCRUMBOARD',
                type     : 'item',
                icon     : 'receipt',
                url      : '/ui/cards'
            }        
        ]
    },    
    {
        id      : 'user-interface',
        title   : 'Administración',
        type    : 'group',
        icon    : 'web',
        children: [
            {
                id   : 'User',
                title: 'Agentes',
                type : 'item',
                icon : 'people',
                url  : '/apps/contacts', 
                badge: {
                    title: '18',
                    bg   : '#EC0C8E',
                    fg   : '#FFFFFF'
                }
            },
            {
                id   : 'Contactos',
                title: 'Contactos',
                type : 'item',
                icon : 'adb',
                url  : '/apps/contacts', 
                badge: {
                    title: '1800',
                    bg   : '#EC0C8E',
                    fg   : '#FFFFFF'
                }
            },
            {
                id   : 'Roles',
                title: 'Roles',
                type : 'item',
                icon : 'color_lens',
                url  : '/angular-material-elements/bottom-sheet'
            }, 
            {
                id       : 'file-manager',
                title    : 'Galeria',
                translate: 'NAV.FILE_MANAGER',
                type     : 'item',
                icon     : 'folder',
                url      : '/apps/file-manager'
            },           
            {
                id   : 'Config',
                title: 'Configuración',
                type : 'item',
                icon : 'build',
                url  : '/angular-material-elements/input',                
            },{
                id   : 'Planes',
                title: 'Planes y Pagos',
                type : 'item',
                icon : 'call_to_action',
                url  : '/apps/e-commerce/pagos',                
            },
            
        ]
    },    
    {
        id      : 'pages',
        title   : 'Integradores',
        type    : 'group',
        icon    : 'pages',
        children: [                 
            {
                id       : 'api',
                title    : 'API',
                translate: 'NAV.ACADEMY',
                type     : 'item',
                icon     : 'school',
                url      : '/apps/academy'
            },
            {
                id   : 'faq',
                title: 'Preguntas',
                type : 'item',
                icon : 'help',
                url  : '/pages/faq'
            },
            {
                id   : 'knowledge-base',
                title: 'Novedades',
                type : 'item',
                icon : 'import_contacts',
                url  : '/pages/knowledge-base'
            }
        ]
    },
   
];
