
export class EtiquetasFakeDb
{
    // Si se edita alguno de los campos aqui presente sobre todo menú
    // asi sea una mayúscula puede dañar el sistema, NO TOCAR MEJOR
    public static etiquetas = {  
        colum: ['etiquetas', 'Canal', 'menú'],
        type: ['string', 'string', 'menu'],
        TitleColumShow: ['Etiquetas', 'Canal', 'Menú'],        
        menu: ["Editar/edit", "Eliminar/delete"],
        clientes:[
            {
                id:1,
                etiquetas:[
                    {
                        word:"Reportando Falla",
                        Lic: ["04142788259"]
                    },
                    {
                        word:"En seguimiento",
                        Lic: ["04142788259"]
                    },
                    {
                        word:"Reportando Pago",
                        Lic: ["04142788259"]
                    },
                    {
                        word:"Nuevo Prospecto",
                        Lic: ["04142788259"]
                    }
                ]
            },
            {
                id:2,
                etiquetas:[
                    {
                        word:"Reportando Falla",
                        Lic: ["04142788259"]
                    },
                    {
                        word:"En seguimiento",
                        Lic: ["04142788259"]
                    },
                    {
                        word:"Reportando Pago",
                        Lic: ["04142788259"]
                    },
                    {
                        word:"Nuevo Prospecto",
                        Lic: ["04142788259"]
                    }
                ]
            }
        ]
    }
}