export class FileManagerFakeDb
{
    /*
                    {
                    'id'  : 0,
                    'title':'img0',
                    'url' : 'assets/images/ecommerce/CH101.jpeg',
                    'type': 'image',
                    'message': 'Proident et sunt nostrud eiusmod ex elit tempor ipsum dolore amet laborum.'
                },
    */
    public static files = [
        {
            'id':0,
            'title':'img0',
            'type': 'image',
            'url' : 'assets/images/ecommerce/CH101.jpeg',
            'message': 'Proident et sunt nostrud eiusmod ex elit tempor ipsum dolore amet laborum.',     
                       
            'owner':   'Cable Hogar',
            'size'     : '1.2 Mb',
            'created'  : 'July 8, 2017',
            'modified' : 'July 8, 2017',
            'offline'  : true,
        },
        {
            'id':1,
            'title':'img1',
            'type': 'image',
            'url' : 'assets/images/ecommerce/CH102.jpeg',
            'message': 'Proident et sunt nostrud eiusmod ex elit tempor ipsum dolore amet laborum.',     
                       
            'owner':   'Cable Hogar',
            'size'     : '1.5 Mb',
            'created'  : 'July 8, 2017',
            'modified' : 'July 8, 2017',
            'offline'  : true,
        },
        {
            'id':2,
            'title':'img2',
            'type': 'image',
            'url' : 'assets/images/ecommerce/CH103.jpeg',
            'message': 'Proident et sunt nostrud eiusmod ex elit tempor ipsum dolore amet laborum.',     
                       
            'owner':   'Cable Hogar',
            'size'     : '1.3 Mb',
            'created'  : 'July 8, 2017',
            'modified' : 'July 8, 2017',
            'offline'  : true,
        },
        {
            'id':3,
            'title':'img3',
            'type': 'image',
            'url' : 'assets/images/ecommerce/CONEX/conex1.jpeg',
            'message': 'Proident et sunt nostrud eiusmod ex elit tempor ipsum dolore amet laborum.',     
                       
            'owner':   'Fibex',
            'size'     : '1.1 Mb',
            'created'  : 'July 8, 2017',
            'modified' : 'July 8, 2017',
            'offline'  : true,
        },
        {
            'id':4,
            'title':'img4',
            'type': 'image',
            'url' : 'assets/images/ecommerce/CONEX/conex1.jpeg',
            'message': 'Proident et sunt nostrud eiusmod ex elit tempor ipsum dolore amet laborum.',     
                       
            'owner':   'Fibex',
            'size'     : '2.2 Mb',
            'created'  : 'July 8, 2017',
            'modified' : 'July 8, 2017',
            'offline'  : true,
        },
        /*{
            'name'     : 'Work Documents',
            'type'     : 'folder',
            'owner'    : 'me',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'location' : 'My Files > Galeria',
            'offline'  : true
        },
        {
            'name'     : 'Public Documents',
            'type'     : 'folder',
            'owner'    : 'public',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'location' : 'My Files > Galeria',
            'offline'  : true
        },
        {
            'name'     : 'Private Documents',
            'type'     : 'folder',
            'owner'    : 'me',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'location' : 'My Files > Galeria',
            'offline'  : true
        },
        {
            'name'     : 'Crash logs',
            'type'     : 'document',
            'owner'    : 'Emily Bennett',
            'size'     : '980 Mb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'location' : 'My Files > Galeria',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'System logs',
            'type'     : 'document',
            'owner'    : 'Emily Bennett',
            'size'     : '52 Kb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'location' : 'My Files > Galeria',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Prices',
            'type'     : 'spreadsheet',
            'owner'    : 'Emily Bennett',
            'size'     : '27 Mb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'location' : 'My Files > Galeria',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Anabelle Manual',
            'type'     : 'document',
            'owner'    : 'Emily Bennett',
            'size'     : '1.1 Kb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'location' : 'My Files > Galeria',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Steam summer sale budget',
            'type'     : 'spreadsheet',
            'owner'    : 'Emily Bennett',
            'size'     : '505 Kb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'location' : 'My Files > Galeria',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        }*/
    ];

}
