export class BotsFakeDb
{
    public static Bot = {  
        title: 'Bot',
        colum: ['contains', 'exact', 'Type', 'response', 'Menu'],
        type: ['string','string', 'boolean', 'string', 'menu'],
        TitleColumShow: ['Contiene', 'Exacto', 'Respuesta', 'Tipo', 'Menú'],
        Lics: [{
                idCliente:1,
                Lic: '584142788259',
                table: { 
                    bot: [
                        {
                            "contains": ["buenos dias"],
                            "exact": ["hi hhh", "hola"],
                            "tipo": "saludo",
                            "response": "Hola, \nGracias por comunicarse con *#EmpresaName#*, empresa líder de Venezuela en servicios Internet de Alta Velocidad por Fibra Óptica para el Hogar, Pyme, Empresas.\n\n Usted podrá realizar múltiples operaciones de forma automática a través de nuestro asesor inteligente Thomas. Elija una opción del siguiente menú:\n\n1.- *Consulta de Saldo:* (Envíe la palabra SALDO separado de su número de Cédula Ej: _SALDO 11222333_)\n\n2.- Reporte de pago: (Envíe *PAGO*)\n\n3.- Reporte de falla: (Envíe *FALLA*)\n\nPor favor tome nota de otras formas de contacto con FIBEX TELECOM\n\n🖥️ *Para reportar averías:* https://fibextelecom.net/reporte-de-fallas/ o fallas@fibextelecom.net\n\n🧾 *Para reportar pagos:* https://fibextelecom.net/registrar-pago/  o pagoshogar@fibextelecom.net \n\n📲 *Centro de Contactos:* 0212 3108300\n\n🗒️ Para mejorar la calidad de atención, por favor llenar esta pequeña encuesta: https://shorturl.at/cpCGV \n\nPara *#EmpresaName#* ha sido un gusto poder atenderle.\n\n",
                            "apiURL": "",
                            "bData": false
                        },
                        {
                            "contains": [], 
                            "exact": ["Carlos"],
                            "tipo": "saludo",
                            "response": "Hola Carlos como estas",
                            "apiURL": "",
                            "bData": false
                        },		
                        {
                            "contains": ["buenos dias"],
                            "exact": ["hi hhh"],
                            "tipo": "saludo",
                            "response": "Buenas dias, \nGracias por comunicarse con *#EmpresaName#*, empresa líder de Venezuela en servicios Internet de Alta Velocidad por Fibra Óptica para el Hogar, Pyme, Empresas.\n\n Usted podrá realizar múltiples operaciones de forma automática a través de nuestro asesor inteligente Thomas. Elija una opción del siguiente menú:\n\n1.- *Consulta de Saldo:* (Envíe la palabra SALDO separado de su número de Cédula Ej: _SALDO 11222333_)\n\n2.- Reporte de pago: (Envíe *PAGO*)\n\n3.- Reporte de falla: (Envíe *FALLA*)\n\nPor favor tome nota de otras formas de contacto con *FIBEX TELECOM*\n\n🖥️ *Para reportar averías:* https://fibextelecom.net/reporte-de-fallas/ o fallas@fibextelecom.net\n\n🧾 *Para reportar pagos:* https://fibextelecom.net/registrar-pago/  o pagoshogar@fibextelecom.net \n\n📲 *Centro de Contactos:* 0212 3108300\n\n🗒️ Para mejorar la calidad de atención, por favor llenar esta pequeña encuesta: https://shorturl.at/cpCGV \n\nPara *#EmpresaName#* ha sido un gusto poder atenderle.\n\n",
                            "apiURL": "",
                            "bData": false
                        },{
                            "contains": ["buenas tardes"],
                            "exact": ["hi hhh"],
                            "tipo": "saludo",
                            "response": "Buenas tardes, \nGracias por comunicarse con *#EmpresaName#*, empresa líder de Venezuela en servicios Internet de Alta Velocidad por Fibra Óptica para el Hogar, Pyme, Empresas.\n\n Usted podrá realizar múltiples operaciones de forma automática a través de nuestro asesor inteligente Thomas. Elija una opción del siguiente menú:\n\n1.- *Consulta de Saldo:* (Envíe la palabra SALDO separado de su número de Cédula Ej: _SALDO 11222333_)\n\n2.- Reporte de pago: (Envíe *PAGO*)\n\n3.- Reporte de falla: (Envíe *FALLA*)\n\nPor favor tome nota de otras formas de contacto con *FIBEX TELECOM*\n\n🖥️ *Para reportar averías:* https://fibextelecom.net/reporte-de-fallas/ o fallas@fibextelecom.net\n\n🧾 *Para reportar pagos:* https://fibextelecom.net/registrar-pago/  o pagoshogar@fibextelecom.net \n\n📲 *Centro de Contactos:* 0212 3108300\n\n🗒️ Para mejorar la calidad de atención, por favor llenar esta pequeña encuesta: https://shorturl.at/cpCGV \n\nPara *#EmpresaName#* ha sido un gusto poder atenderle.\n\n",
                            "apiURL": "",
                            "bData": false
                        },{
                            "contains": ["buenas noches"],
                            "exact": ["hi hhh"],
                            "tipo": "saludo",
                            "response": "Buenas noches, \nGracias por comunicarse con *#EmpresaName#*, empresa líder de Venezuela en servicios Internet de Alta Velocidad por Fibra Óptica para el Hogar, Pyme, Empresas.\n\n Usted podrá realizar múltiples operaciones de forma automática a través de nuestro asesor inteligente Thomas. Elija una opción del siguiente menú:\n\n1.- *Consulta de Saldo:* (Envíe la palabra SALDO separado de su número de Cédula Ej: _SALDO 11222333_)\n\n2.- Reporte de pago: (Envíe *PAGO*)\n\n3.- Reporte de falla: (Envíe *FALLA*)\n\nPor favor tome nota de otras formas de contacto con *FIBEX TELECOM*\n\n🖥️ *Para reportar averías:* https://fibextelecom.net/reporte-de-fallas/ o fallas@fibextelecom.net\n\n🧾 *Para reportar pagos:* https://fibextelecom.net/registrar-pago/  o pagoshogar@fibextelecom.net \n\n📲 *Centro de Contactos:* 0212 3108300\n\n🗒️ Para mejorar la calidad de atención, por favor llenar esta pequeña encuesta: https://shorturl.at/cpCGV \n\nPara *#EmpresaName#* ha sido un gusto poder atenderle.\n\n",
                            "apiURL": "",
                            "bData": false
                        },
                        {
                            "contains": ["saldo","deuda","cual es el monto","saber el monto ","la deuda","la deuda pendiente","Consulta de saldo","saber cuanto","saldo de","mi cuenta","consultar","cuando es mi pago","saldo deudor", "mi deuda","el saldo","monto pendientes","Consulta de saldo","consulta de saldo","fecha de pago"," monto pendiente ","me gustaria pagar"],
                            "exact": ["gggggg"],
                            "temp": "Su última fecha de pago fue el *#ultimo_fecha_pago#* por un monto de *#ultimo_monto_pago# USD*.\n\n",
                            "response": "Gracias por comunicarse con el Centro de Contactos #EmpresaName# *#cliente# C.I. #cedula#*. Le saluda su asesor Inteligente Thomas.\n\nLe informamos que su servicio con el contrato Nro. #nro_contrato#, se encuentra #status_contrato#. \n\nSu cuenta posee un saldo pendiente por pagar de *#saldo# USD* que puede cancelar en bolívares, a la tasa de cambio del Banco Central de Venezuela. Si cree que este monto no es correcto y/o han transcurrido más de 48 horas desde que realizó y reportó su pago, por favor envíe un email a pagoshogar@fibextelecom.net) para proceder con las correcciones.\n\n\n\nSu suscripción mensual es de *#suscripcion# USD* que debe cancelar los 5 primeros días de cada mes, para evitar suspensión del servicio y cargos por reconexión. Para su comodidad, adjunto los métodos de pago.\n\n",
                            "tipo": "saldo",
                            "apiURL": "https://api.thomas-talk.me/SaldoCe/#cedula#/#lic#",
                            "file":"Bancos.png",
                            "bData": false
                        },
                        {
                            "contains": [],
                            "exact": ["llamadas"],
                            "tipo": "llamada",
                            "response": "Estimado usuario, le informamos que usted se ha comunicando por nuestra linea de atención WhatsApp. Le invitamos a escribir su solicitud, para ser atendida por el primer asesor disponible. Agradecemos su tiempo en espera y quedamos atentos.",
                            "file":"",
                            "apiURL": "",
                            "bData": false
                        },
                        {            
                            "contains": ["contrato", "nuevo contrato"],
                            "exact": ["contrato"],
                            "tipo": "contrato",
                            "response": "Estimado Cliente:\n\nSirva la presente para informarle que a partir del 24-07-2020 hemos iniciado un proceso de cambio de contratos de servicios de INTERNET de la empresa FIBEX Telecom, a nuestra empresa matriz *FIBEX TELECOM*, que opera bajo la marca comercial registrada CableHOGAR.\n\n Este cambio no afectará los servicios suscritos y se hará, con los mismos términos y condiciones de su contrato actual. Para este fin, debemos entregarle el nuevo contrato antes del día 05 de agosto y para ello, le ofrecemos 3 opciones:\n\n▪ Enviarle el contrato por correo electrónico: comunicaciones@fibextelecom.net y usted lo devuelve firmado en digital.\n▪️ Puede dirigirse previa cita a nuestra oficina comercial ubicada en ubicada en Valencia, Torre A1 PB en La Viña. \n▪ Entrega en su domicilio previa cita.\n\nPor favor déjenos saber qué opción prefiere para realizar el cambio de contrato a través de esta misma vía o, puede confirmar a través de nuestro Centro de Contactos por el +58 212 310 83.00.\n\nUna vez más, agradecemos su confianza y tenga la seguridad que seguiremos trabajando día a día para brindarle el mejor servicio.\n\nAtentamente,\n\n Dpto. de Comunicaciones CableHOGAR\nCentro de Contactos: +58 212 310.83.00\nWhatsApp: +58 412 020.20.20\nEmail: comunicaciones@fibextelecom.net\n",
                            "file":"",
                            "apiURL": "",
                            "bData": false
                        },
                        {
                            "contains": ["banco", "transferencia"],
                            "exact": ["hiee"],
                            "tipo": "datosbanco",
                            "response": "cuentas",
                            "file":"Bancos.jpg",
                            "apiURL": "",
                            "bData": false
                        },
                        {
                            "contains": ["horario"],
                            "exact": ["hey"],
                            "response": "Gracias por comunicarse con #EmpresaName#. Nuestro horario de atención \n WhatsApp es de lunes a viernes  desde las 8 am. hasta las 5 pm.",
                            "apiURL": "",
                            "bData": false
                        },{
                            "contains": ["no tengo servicio","no hay señal", "falla", "sin servicio",  "sin señal", "sin senal", "estan caidos"],
                            "exact": ["falla", "3"],
                            "tipo": "sinservicio",
                            "response": "Estimado Cliente:\n\n Gracias por comunicarse con el Centro de Contactos *FIBEX TELECOM*. le escribe su asesor inteligente Thomas.\n\n Pedimos disculpas por los inconvenientes y le informamos que procederemos a realizar el registro de su falla.",                    
                            "apiURL": "",
                            "bData": true,            
                            "getData": ["Por favor escriba su *nombre completo* ahora.","Gracias *#Data0#*. Ahora escriba la cédula de identidad del titular del servicio (ejemplo: 1155666):","#saldo1#Por favor escriba un correo electrónico válido (ejemplo: tucorreo@gmail.com)","Sr.(a) *#Data0#*. Según la cédula de identidad Nro. #Data1# su servicio se encuentra (#status_contrato#). Su saldo pendiente es de #saldo# USD. Por favor, elija una opción del siguiente menú: \n\n1.- No Disfruta la Velocidad Contratada.\n2.- No tiene servicio \n3.- Conexión lenta \n4.- Equipo no enciende \n5.- Páginas bloqueadas \n6.- Cambios de clave \n\n","Indicar desde qué fecha su servicio presenta fallas (ejemplo: 25/01/2020).","Ya casi terminamos Sr.(a) *#Data0#*. Si gusta, puede agregar alguna nota para nuestro asesor inteligente Thomas.","Para finalizar, si gusta puede enviarnos una imagen con la falla. De lo contrario, escriba la palabra FIN.","#FINALIZAR#Gracias por la información:\n\n.- Nombre: *#Data0#*\n.- Número de Identidad: *#Data1#*\n.- Correo: *#Data2#*\n.- Tipo de falla: *#Data3#*\n.- Fecha: *#Data4#*\n.- Nota: *#Data5#*\n\nAhora también puede reportar fallas directamente a través de nuestra E-mail: fallas@fibextelecom.net \n\nPara mejorar la calidad de atención, agradecemos nos ayude llenado la siguiente encuesta: https://shorturl.at/cpCGV\n\n Para *FIBEX Telecom* ha sido un gusto poder atenderle."],
                            "getDataType": ["name", "number", "email", "any", "date", "note", "any"],
                            "InvalidateInput": ["Es importante que la información sea la correcta, me puedes indicar de nuevo su *Nombre Completo*:", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *Documento Identidad*:", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *Correo*:", "", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *fecha del pago* Ej: 25/01/2020", "", ""]
                        },
                        {
                            "contains": ["operador", "operator"],
                            "exact": [""],
                            "response": "Estimado cliente su caso va ser asignado a uno de nuestros asesores, espere con calma, pronto será atendido.\n\nPara agilizar el proceso podría indicar \n.- Nombre y Apellido\n.-Documento de identidad:\n.- Requerimiento:",
                            "file":"Bancos.jpg",
                            "apiURL": "",
                            "bData": false
                        },
                        {
                            "contains": [],
                            "exact": ["1"],
                            "response": "Entiendo que desea consultar su saldo para ello debe enviar la palabra SALDO seguido de su cédula sin punto, Ej: SALDO 11555566",
                            "apiURL": "",
                            "bData": false
                        },
                        {
                            "contains": ["planes", "costo de instalaci", "mas informaci", "más informaci", "para informaci", "Hay cobertura para", "Quiero es información", "quiero información"],
                            "exact": ["información", "4"],
                            "response": "Gracias por comunicarse con el Centro de Contactos *FIBEX TELECOM*, le saluda la asesora inteligente Thomas, Gracias por su interés en nuestros servicios de Internet de alta velocidad por Fibra Óptica hasta el hogar, para ayudarlo con la información nuestro asesor inteligente Thomas le va a solicitar una sería de datos:\n\n El servicio solicitado es para Empresa o para el Hogar.",
                            "file": "",
                            "apiURL": "",
                            "bData": true,
                            "getData": ["por favor me podría indicar su *Nombre Completo*:", "Ahora necesito su número de *Documento Identidad*, Ej: 1155666", "Gracias #Data1#, necesito *su correo*:", "En qué *ciudad* se encuenta ubicado?", "Nos podría indicar cual es la Zona/Sector/Parroquia?", "Ya casi terminamos, me indicas la *Dirección*:", "Si te encuentras en el lugar, compartenos *tú ubicación*\n en caso contrario enviar NO:", "Para finalizar, \npuedes agregar alguna *nota* para nuestro personal:", "#FINALIZAR#Gracias esta es la información ya un asesor lo va a contactar con los datos: \n\n.- Nombre Completo: #Data0#\n.- Documento Identidad: #Data1#\n.- Correo: #Data2#\n.- Ciudad: #Data3#\n.- Zona/Sector/Parroquia: #Data4#\n.- Dirección: #Data5#\n.- Location: #Data6#\n.- Nota: #Data7#\n\n  Para #EmpresaName# es un gusto poder atenderle.\n\nPara mejorar la calidad de atención, llene esta pequeña encuesta: https://shorturl.at/cpCGV"]
                        },
                        {
                            "contains": ["reportar pago", "reporte", "informar pago", "Referencia"],
                            "exact": ["pago", "2"],
                            "response": "Nuestro asesor inteligente Thomas realizará el registro del pago de sus servicios. Por favor tenga a mano los datos bancarios de su pago.",
                            "file":"Bancos.jpg",
                            "apiURL": "",
                            "tipo": "reporte",
                            "bData": true,
                            "getData": ["Indique su *nombre completo* ahora:", "Gracias #Data0#, Ahora escriba la cédula de identidad del titular del servicio (ejemplo: 1155666):", "Por favor escriba un correo electrónico válido (ejemplo: tucorreo@gmail.com)#saldo1#", "Gracias #Data0#. Su contrato de servicios con cédula de identidad Nro. #Data1# presenta un saldo pendiente de #Rsaldo# USD. A continuación, escriba el monto en bolívares del pago que realizó. Use coma (,) para decimales (ejemplo: 1678390,98).", "Ahora debe escribir el nombre del Banco al que realizó su pago (ejemplo: Banco Mercantil).", "Ahora debe escribir la fecha de pago (ejemplo: 25/01/2020).", "Ya casi terminamos #Data0#. Ahora escriba todos los números correspondientes a la referencia bancaria (Ejemplo: 112233445566).","Para finalizar, envíe el capture de la transacción bancaria ahora.","Si gusta, puede agregar alguna nota para nuestro asesor inteligente Thomas. De lo contrario, escriba la palabra FIN.", "#FINALIZAR#Gracias por su información. La información que será registrada en nuestro sistema es:\n\n.- Nombre: #Data0#\n\n.- Número de Identidad: #Data1#\n\n.- Corre: #Data2#\n\n.- Monto a Registrar: #Data3#\n\n.- Banco: #Data4#\n\n.- Fecha: #Data5#\n\n.- Número de Comprobante: #Data6#\n\n.- Nota: #Data7#\n\n Le recordamos que el proceso de conciliación con el Banco emisor, se establece en un lapso de 24 a 48 horas hábiles. Evite cargos por reconexión, cancelando su factura de servicios los 5 primeros días de cada mes.\n\n *IMPORTANTE: Si los datos suministrados no son correctos*, por favor envíe un email a pagoshogar@fibextelecom.net con la información correcta y con mucho gusto haremos las correcciones en sistema.\n\nPara mejorar la calidad de atención, agradecemos nos ayude llenado la siguiente encuesta: https://shorturl.at/cpCGV\n\n Para *#EmpresaName#* ha sido un gusto poder atenderle."],
                            "getDataType": ["name", "cedula", "email", "money", "any", "date", "number", "note", "any"],
                            "getRequerido": [true, true, true, true, false, true, true, false, false],
                            "InvalidateInput": ["Es importante que la información sea la correcta, me puedes indicar de nuevo su *Nombre Completo*:", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *Documento Identidad*:", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *Correo*:", "", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *fecha del pago* Ej: 25/01/2020", "", ""]
                        },{
                            "contains": ["si quiero mz"],
                            "exact": ["si"],
                            "response": "Felicitación, estas muy cerca de ampliar su ancho de banda a 100 Mbps x 30 días *TOTALMENTE GRATIS!*,",
                            "file":"",
                            "apiURL": "",
                            "tipo": "reporte",
                            "bData": true,
                            "getData": ["\nPor favor escriba la cédula de identidad del titular del servicio *Fibex Telecom* (ejemplo: 9094567).", "#saldo0#Gracias Sr(a) #cliente#  Por favor, escriba el número telefónico de su referido ahora (ejemplo: 04167435698).", "Ya casi terminamos, por favor escriba el nombre de su referido ahora, (ejemplo: María Perdomo).","#FINALIZAR#Gracias por los datos suministrados. \n*Fibex Telecom* su proveedor de servicios de Internet le da las gracias y le mantendrá informado(a) si su referido, estuvo de acuerdo con suscribirse a nuestros servicios."],
                            "getDataType": ["name", "cedula", "email", "money", "any", "date", "number", "note", "any"],
                            "getRequerido": [true, true, true, true, false, true, true, false, false],
                            "InvalidateInput": ["Es importante que la información sea la correcta, me puedes indicar de nuevo su *Nombre Completo*:", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *Documento Identidad*:", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *Correo*:", "", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *fecha del pago* Ej: 25/01/2020", "", ""]
                        }
                    ],
                    noMatch: "{Gracias por comunicarse con *#EmpresaName#*, empresa líder de Venezuela en servicios Internet de Alta Velocidad por Fibra Óptica para el Hogar, Pyme, Empresas.\n\n Usted podrá realizar múltiples operaciones de forma automática a través de nuestro asesor inteligente Thomas. Elija una opción del siguiente menú:\n\n1.- *Consulta de Saldo:* (Envíe la palabra SALDO separado de su número de Cédula Ej: _SALDO 11222333_)\n\n2.- Reporte de pago: (Envíe *PAGO*)\n\n3.- Reporte de falla: (Envíe *FALLA*)\n\nPor favor tome nota de otras formas de contacto con *FIBEX TELECOM:* (CableHOGAR)\n\n🖥️ *Para reportar averías:* https://fibextelecom.net/reporte-de-fallas/ o fallas@fibextelecom.net\n\n🧾 *Para reportar pagos:* https://fibextelecom.net/registrar-pago/  o pagoshogar@fibextelecom.net \n\n📲 *Centro de Contactos:* 0212 3108300\n\n🗒️ Para mejorar la calidad de atención, por favor llenar esta pequeña encuesta: https://shorturl.at/cpCGV \n\nPara *#EmpresaName#* ha sido un gusto poder atenderle.\n\n|Gracias por comunicarse con el Centro de Contactos *FIBEX TELECOM*, empresa líder de Venezuela en servicios de TV Cable e Internet hasta su hogar.\n\n Usted podrá realizar múltiples operaciones de forma automática a través de nuestro asesor inteligente Thomas. Elija una opción del siguiente menú:\n\n1.- *Consulta de Saldo:* (Envíe la palabra SALDO separado de su número de Cédula Ej: _SALDO 11222333_)\n\n2.- Reporte de pago: (Envíe *PAGO*)\n\n3.- Reporte de falla: (Envíe *FALLA*)\n\nPor favor tome nota de otras formas de contacto:\n\n🖥️ *Para reportar averías:* fallas@fibextelecom.net\n\n🧾 *Para reportar pagos:* pagoshogar@fibextelecom.net \n\n📲 *Centro de Contactos:* 0212 3108300\n\n🗒️ Para mejorar la calidad de atención, por favor llenar esta pequeña encuesta: https://shorturl.at/cpCGV \n\nPara *FIBEX TELECOM* ha sido un gusto poder atenderle.\n\n}",
                }
            },{
                idCliente:1,
                Lic: '584141363700',
                table: { 
                    bot: [
                        {
                            "contains": ["buenos dias"],
                            "exact": ["hi hhh", "hola"],
                            "tipo": "saludo",
                            "response": "Hola, \nGracias por comunicarse con *#EmpresaName#*, empresa líder de Venezuela en servicios Internet de Alta Velocidad por Fibra Óptica para el Hogar, Pyme, Empresas.\n\n Usted podrá realizar múltiples operaciones de forma automática a través de nuestro asesor inteligente Thomas. Elija una opción del siguiente menú:\n\n1.- *Consulta de Saldo:* (Envíe la palabra SALDO separado de su número de Cédula Ej: _SALDO 11222333_)\n\n2.- Reporte de pago: (Envíe *PAGO*)\n\n3.- Reporte de falla: (Envíe *FALLA*)\n\nPor favor tome nota de otras formas de contacto con FIBEX TELECOM\n\n🖥️ *Para reportar averías:* https://fibextelecom.net/reporte-de-fallas/ o fallas@fibextelecom.net\n\n🧾 *Para reportar pagos:* https://fibextelecom.net/registrar-pago/  o pagoshogar@fibextelecom.net \n\n📲 *Centro de Contactos:* 0212 3108300\n\n🗒️ Para mejorar la calidad de atención, por favor llenar esta pequeña encuesta: https://shorturl.at/cpCGV \n\nPara *#EmpresaName#* ha sido un gusto poder atenderle.\n\n",
                            "apiURL": "",
                            "bData": false
                        },
                        {
                            "contains": [], 
                            "exact": ["Carlos"],
                            "tipo": "saludo",
                            "response": "Hola Carlos como estas",
                            "apiURL": "",
                            "bData": false
                        },		
                        {
                            "contains": ["buenos dias"],
                            "exact": ["hi hhh"],
                            "tipo": "saludo",
                            "response": "Buenas dias, \nGracias por comunicarse con *#EmpresaName#*, empresa líder de Venezuela en servicios Internet de Alta Velocidad por Fibra Óptica para el Hogar, Pyme, Empresas.\n\n Usted podrá realizar múltiples operaciones de forma automática a través de nuestro asesor inteligente Thomas. Elija una opción del siguiente menú:\n\n1.- *Consulta de Saldo:* (Envíe la palabra SALDO separado de su número de Cédula Ej: _SALDO 11222333_)\n\n2.- Reporte de pago: (Envíe *PAGO*)\n\n3.- Reporte de falla: (Envíe *FALLA*)\n\nPor favor tome nota de otras formas de contacto con *FIBEX TELECOM*\n\n🖥️ *Para reportar averías:* https://fibextelecom.net/reporte-de-fallas/ o fallas@fibextelecom.net\n\n🧾 *Para reportar pagos:* https://fibextelecom.net/registrar-pago/  o pagoshogar@fibextelecom.net \n\n📲 *Centro de Contactos:* 0212 3108300\n\n🗒️ Para mejorar la calidad de atención, por favor llenar esta pequeña encuesta: https://shorturl.at/cpCGV \n\nPara *#EmpresaName#* ha sido un gusto poder atenderle.\n\n",
                            "apiURL": "",
                            "bData": false
                        },{
                            "contains": ["buenas tardes"],
                            "exact": ["hi hhh"],
                            "tipo": "saludo",
                            "response": "Buenas tardes, \nGracias por comunicarse con *#EmpresaName#*, empresa líder de Venezuela en servicios Internet de Alta Velocidad por Fibra Óptica para el Hogar, Pyme, Empresas.\n\n Usted podrá realizar múltiples operaciones de forma automática a través de nuestro asesor inteligente Thomas. Elija una opción del siguiente menú:\n\n1.- *Consulta de Saldo:* (Envíe la palabra SALDO separado de su número de Cédula Ej: _SALDO 11222333_)\n\n2.- Reporte de pago: (Envíe *PAGO*)\n\n3.- Reporte de falla: (Envíe *FALLA*)\n\nPor favor tome nota de otras formas de contacto con *FIBEX TELECOM*\n\n🖥️ *Para reportar averías:* https://fibextelecom.net/reporte-de-fallas/ o fallas@fibextelecom.net\n\n🧾 *Para reportar pagos:* https://fibextelecom.net/registrar-pago/  o pagoshogar@fibextelecom.net \n\n📲 *Centro de Contactos:* 0212 3108300\n\n🗒️ Para mejorar la calidad de atención, por favor llenar esta pequeña encuesta: https://shorturl.at/cpCGV \n\nPara *#EmpresaName#* ha sido un gusto poder atenderle.\n\n",
                            "apiURL": "",
                            "bData": false
                        },{
                            "contains": ["buenas noches"],
                            "exact": ["hi hhh"],
                            "tipo": "saludo",
                            "response": "Buenas noches, \nGracias por comunicarse con *#EmpresaName#*, empresa líder de Venezuela en servicios Internet de Alta Velocidad por Fibra Óptica para el Hogar, Pyme, Empresas.\n\n Usted podrá realizar múltiples operaciones de forma automática a través de nuestro asesor inteligente Thomas. Elija una opción del siguiente menú:\n\n1.- *Consulta de Saldo:* (Envíe la palabra SALDO separado de su número de Cédula Ej: _SALDO 11222333_)\n\n2.- Reporte de pago: (Envíe *PAGO*)\n\n3.- Reporte de falla: (Envíe *FALLA*)\n\nPor favor tome nota de otras formas de contacto con *FIBEX TELECOM*\n\n🖥️ *Para reportar averías:* https://fibextelecom.net/reporte-de-fallas/ o fallas@fibextelecom.net\n\n🧾 *Para reportar pagos:* https://fibextelecom.net/registrar-pago/  o pagoshogar@fibextelecom.net \n\n📲 *Centro de Contactos:* 0212 3108300\n\n🗒️ Para mejorar la calidad de atención, por favor llenar esta pequeña encuesta: https://shorturl.at/cpCGV \n\nPara *#EmpresaName#* ha sido un gusto poder atenderle.\n\n",
                            "apiURL": "",
                            "bData": false
                        },
                        {
                            "contains": ["saldo","deuda","cual es el monto","saber el monto ","la deuda","la deuda pendiente","Consulta de saldo","saber cuanto","saldo de","mi cuenta","consultar","cuando es mi pago","saldo deudor", "mi deuda","el saldo","monto pendientes","Consulta de saldo","consulta de saldo","fecha de pago"," monto pendiente ","me gustaria pagar"],
                            "exact": ["gggggg"],
                            "temp": "Su última fecha de pago fue el *#ultimo_fecha_pago#* por un monto de *#ultimo_monto_pago# USD*.\n\n",
                            "response": "Gracias por comunicarse con el Centro de Contactos #EmpresaName# *#cliente# C.I. #cedula#*. Le saluda su asesor Inteligente Thomas.\n\nLe informamos que su servicio con el contrato Nro. #nro_contrato#, se encuentra #status_contrato#. \n\nSu cuenta posee un saldo pendiente por pagar de *#saldo# USD* que puede cancelar en bolívares, a la tasa de cambio del Banco Central de Venezuela. Si cree que este monto no es correcto y/o han transcurrido más de 48 horas desde que realizó y reportó su pago, por favor envíe un email a pagoshogar@fibextelecom.net) para proceder con las correcciones.\n\n\n\nSu suscripción mensual es de *#suscripcion# USD* que debe cancelar los 5 primeros días de cada mes, para evitar suspensión del servicio y cargos por reconexión. Para su comodidad, adjunto los métodos de pago.\n\n",
                            "tipo": "saldo",
                            "apiURL": "https://api.thomas-talk.me/SaldoCe/#cedula#/#lic#",
                            "file":"Bancos.png",
                            "bData": false
                        },
                        {
                            "contains": [],
                            "exact": ["llamadas"],
                            "tipo": "llamada",
                            "response": "Estimado usuario, le informamos que usted se ha comunicando por nuestra linea de atención WhatsApp. Le invitamos a escribir su solicitud, para ser atendida por el primer asesor disponible. Agradecemos su tiempo en espera y quedamos atentos.",
                            "file":"",
                            "apiURL": "",
                            "bData": false
                        },
                        {            
                            "contains": ["contrato", "nuevo contrato"],
                            "exact": ["contrato"],
                            "tipo": "contrato",
                            "response": "Estimado Cliente:\n\nSirva la presente para informarle que a partir del 24-07-2020 hemos iniciado un proceso de cambio de contratos de servicios de INTERNET de la empresa FIBEX Telecom, a nuestra empresa matriz *FIBEX TELECOM*, que opera bajo la marca comercial registrada CableHOGAR.\n\n Este cambio no afectará los servicios suscritos y se hará, con los mismos términos y condiciones de su contrato actual. Para este fin, debemos entregarle el nuevo contrato antes del día 05 de agosto y para ello, le ofrecemos 3 opciones:\n\n▪ Enviarle el contrato por correo electrónico: comunicaciones@fibextelecom.net y usted lo devuelve firmado en digital.\n▪️ Puede dirigirse previa cita a nuestra oficina comercial ubicada en ubicada en Valencia, Torre A1 PB en La Viña. \n▪ Entrega en su domicilio previa cita.\n\nPor favor déjenos saber qué opción prefiere para realizar el cambio de contrato a través de esta misma vía o, puede confirmar a través de nuestro Centro de Contactos por el +58 212 310 83.00.\n\nUna vez más, agradecemos su confianza y tenga la seguridad que seguiremos trabajando día a día para brindarle el mejor servicio.\n\nAtentamente,\n\n Dpto. de Comunicaciones CableHOGAR\nCentro de Contactos: +58 212 310.83.00\nWhatsApp: +58 412 020.20.20\nEmail: comunicaciones@fibextelecom.net\n",
                            "file":"",
                            "apiURL": "",
                            "bData": false
                        },
                        {
                            "contains": ["banco", "transferencia"],
                            "exact": ["hiee"],
                            "tipo": "datosbanco",
                            "response": "cuentas",
                            "file":"Bancos.jpg",
                            "apiURL": "",
                            "bData": false
                        },
                        {
                            "contains": ["horario"],
                            "exact": ["hey"],
                            "response": "Gracias por comunicarse con #EmpresaName#. Nuestro horario de atención \n WhatsApp es de lunes a viernes  desde las 8 am. hasta las 5 pm.",
                            "apiURL": "",
                            "bData": false
                        },{
                            "contains": ["no tengo servicio","no hay señal", "falla", "sin servicio",  "sin señal", "sin senal", "estan caidos"],
                            "exact": ["falla", "3"],
                            "tipo": "sinservicio",
                            "response": "Estimado Cliente:\n\n Gracias por comunicarse con el Centro de Contactos *FIBEX TELECOM*. le escribe su asesor inteligente Thomas.\n\n Pedimos disculpas por los inconvenientes y le informamos que procederemos a realizar el registro de su falla.",                    
                            "apiURL": "",
                            "bData": true,            
                            "getData": ["Por favor escriba su *nombre completo* ahora.","Gracias *#Data0#*. Ahora escriba la cédula de identidad del titular del servicio (ejemplo: 1155666):","#saldo1#Por favor escriba un correo electrónico válido (ejemplo: tucorreo@gmail.com)","Sr.(a) *#Data0#*. Según la cédula de identidad Nro. #Data1# su servicio se encuentra (#status_contrato#). Su saldo pendiente es de #saldo# USD. Por favor, elija una opción del siguiente menú: \n\n1.- No Disfruta la Velocidad Contratada.\n2.- No tiene servicio \n3.- Conexión lenta \n4.- Equipo no enciende \n5.- Páginas bloqueadas \n6.- Cambios de clave \n\n","Indicar desde qué fecha su servicio presenta fallas (ejemplo: 25/01/2020).","Ya casi terminamos Sr.(a) *#Data0#*. Si gusta, puede agregar alguna nota para nuestro asesor inteligente Thomas.","Para finalizar, si gusta puede enviarnos una imagen con la falla. De lo contrario, escriba la palabra FIN.","#FINALIZAR#Gracias por la información:\n\n.- Nombre: *#Data0#*\n.- Número de Identidad: *#Data1#*\n.- Correo: *#Data2#*\n.- Tipo de falla: *#Data3#*\n.- Fecha: *#Data4#*\n.- Nota: *#Data5#*\n\nAhora también puede reportar fallas directamente a través de nuestra E-mail: fallas@fibextelecom.net \n\nPara mejorar la calidad de atención, agradecemos nos ayude llenado la siguiente encuesta: https://shorturl.at/cpCGV\n\n Para *FIBEX Telecom* ha sido un gusto poder atenderle."],
                            "getDataType": ["name", "number", "email", "any", "date", "note", "any"],
                            "InvalidateInput": ["Es importante que la información sea la correcta, me puedes indicar de nuevo su *Nombre Completo*:", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *Documento Identidad*:", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *Correo*:", "", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *fecha del pago* Ej: 25/01/2020", "", ""]
                        },
                        {
                            "contains": ["operador", "operator"],
                            "exact": [""],
                            "response": "Estimado cliente su caso va ser asignado a uno de nuestros asesores, espere con calma, pronto será atendido.\n\nPara agilizar el proceso podría indicar \n.- Nombre y Apellido\n.-Documento de identidad:\n.- Requerimiento:",
                            "file":"Bancos.jpg",
                            "apiURL": "",
                            "bData": false
                        },
                        {
                            "contains": [],
                            "exact": ["1"],
                            "response": "Entiendo que desea consultar su saldo para ello debe enviar la palabra SALDO seguido de su cédula sin punto, Ej: SALDO 11555566",
                            "apiURL": "",
                            "bData": false
                        },
                        {
                            "contains": ["planes", "costo de instalaci", "mas informaci", "más informaci", "para informaci", "Hay cobertura para", "Quiero es información", "quiero información"],
                            "exact": ["información", "4"],
                            "response": "Gracias por comunicarse con el Centro de Contactos *FIBEX TELECOM*, le saluda la asesora inteligente Thomas, Gracias por su interés en nuestros servicios de Internet de alta velocidad por Fibra Óptica hasta el hogar, para ayudarlo con la información nuestro asesor inteligente Thomas le va a solicitar una sería de datos:\n\n El servicio solicitado es para Empresa o para el Hogar.",
                            "file": "",
                            "apiURL": "",
                            "bData": true,
                            "getData": ["por favor me podría indicar su *Nombre Completo*:", "Ahora necesito su número de *Documento Identidad*, Ej: 1155666", "Gracias #Data1#, necesito *su correo*:", "En qué *ciudad* se encuenta ubicado?", "Nos podría indicar cual es la Zona/Sector/Parroquia?", "Ya casi terminamos, me indicas la *Dirección*:", "Si te encuentras en el lugar, compartenos *tú ubicación*\n en caso contrario enviar NO:", "Para finalizar, \npuedes agregar alguna *nota* para nuestro personal:", "#FINALIZAR#Gracias esta es la información ya un asesor lo va a contactar con los datos: \n\n.- Nombre Completo: #Data0#\n.- Documento Identidad: #Data1#\n.- Correo: #Data2#\n.- Ciudad: #Data3#\n.- Zona/Sector/Parroquia: #Data4#\n.- Dirección: #Data5#\n.- Location: #Data6#\n.- Nota: #Data7#\n\n  Para #EmpresaName# es un gusto poder atenderle.\n\nPara mejorar la calidad de atención, llene esta pequeña encuesta: https://shorturl.at/cpCGV"]
                        },
                        {
                            "contains": ["reportar pago", "reporte", "informar pago", "Referencia"],
                            "exact": ["pago", "2"],
                            "response": "Nuestro asesor inteligente Thomas realizará el registro del pago de sus servicios. Por favor tenga a mano los datos bancarios de su pago.",
                            "file":"Bancos.jpg",
                            "apiURL": "",
                            "tipo": "reporte",
                            "bData": true,
                            "getData": ["Indique su *nombre completo* ahora:", "Gracias #Data0#, Ahora escriba la cédula de identidad del titular del servicio (ejemplo: 1155666):", "Por favor escriba un correo electrónico válido (ejemplo: tucorreo@gmail.com)#saldo1#", "Gracias #Data0#. Su contrato de servicios con cédula de identidad Nro. #Data1# presenta un saldo pendiente de #Rsaldo# USD. A continuación, escriba el monto en bolívares del pago que realizó. Use coma (,) para decimales (ejemplo: 1678390,98).", "Ahora debe escribir el nombre del Banco al que realizó su pago (ejemplo: Banco Mercantil).", "Ahora debe escribir la fecha de pago (ejemplo: 25/01/2020).", "Ya casi terminamos #Data0#. Ahora escriba todos los números correspondientes a la referencia bancaria (Ejemplo: 112233445566).","Para finalizar, envíe el capture de la transacción bancaria ahora.","Si gusta, puede agregar alguna nota para nuestro asesor inteligente Thomas. De lo contrario, escriba la palabra FIN.", "#FINALIZAR#Gracias por su información. La información que será registrada en nuestro sistema es:\n\n.- Nombre: #Data0#\n\n.- Número de Identidad: #Data1#\n\n.- Corre: #Data2#\n\n.- Monto a Registrar: #Data3#\n\n.- Banco: #Data4#\n\n.- Fecha: #Data5#\n\n.- Número de Comprobante: #Data6#\n\n.- Nota: #Data7#\n\n Le recordamos que el proceso de conciliación con el Banco emisor, se establece en un lapso de 24 a 48 horas hábiles. Evite cargos por reconexión, cancelando su factura de servicios los 5 primeros días de cada mes.\n\n *IMPORTANTE: Si los datos suministrados no son correctos*, por favor envíe un email a pagoshogar@fibextelecom.net con la información correcta y con mucho gusto haremos las correcciones en sistema.\n\nPara mejorar la calidad de atención, agradecemos nos ayude llenado la siguiente encuesta: https://shorturl.at/cpCGV\n\n Para *#EmpresaName#* ha sido un gusto poder atenderle."],
                            "getDataType": ["name", "cedula", "email", "money", "any", "date", "number", "note", "any"],
                            "getRequerido": [true, true, true, true, false, true, true, false, false],
                            "InvalidateInput": ["Es importante que la información sea la correcta, me puedes indicar de nuevo su *Nombre Completo*:", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *Documento Identidad*:", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *Correo*:", "", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *fecha del pago* Ej: 25/01/2020", "", ""]
                        },{
                            "contains": ["si quiero mz"],
                            "exact": ["si"],
                            "response": "Felicitación, estas muy cerca de ampliar su ancho de banda a 100 Mbps x 30 días *TOTALMENTE GRATIS!*,",
                            "file":"",
                            "apiURL": "",
                            "tipo": "reporte",
                            "bData": true,
                            "getData": ["\nPor favor escriba la cédula de identidad del titular del servicio *Fibex Telecom* (ejemplo: 9094567).", "#saldo0#Gracias Sr(a) #cliente#  Por favor, escriba el número telefónico de su referido ahora (ejemplo: 04167435698).", "Ya casi terminamos, por favor escriba el nombre de su referido ahora, (ejemplo: María Perdomo).","#FINALIZAR#Gracias por los datos suministrados. \n*Fibex Telecom* su proveedor de servicios de Internet le da las gracias y le mantendrá informado(a) si su referido, estuvo de acuerdo con suscribirse a nuestros servicios."],
                            "getDataType": ["name", "cedula", "email", "money", "any", "date", "number", "note", "any"],
                            "getRequerido": [true, true, true, true, false, true, true, false, false],
                            "InvalidateInput": ["Es importante que la información sea la correcta, me puedes indicar de nuevo su *Nombre Completo*:", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *Documento Identidad*:", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *Correo*:", "", "Es importante que la información sea la correcta, me puedes indicar de nuevo su *fecha del pago* Ej: 25/01/2020", "", ""]
                        }
                    ],
                    "noMatch":"{Gracias por comunicarse con *#EmpresaName#*, empresa líder de Venezuela en servicios Internet de Alta Velocidad por Fibra Óptica para el Hogar, Pyme, Empresas.\n\n Usted podrá realizar múltiples operaciones de forma automática a través de nuestro asesor inteligente Thomas. Elija una opción del siguiente menú:\n\n1.- *Consulta de Saldo:* (Envíe la palabra SALDO separado de su número de Cédula Ej: _SALDO 11222333_)\n\n2.- Reporte de pago: (Envíe *PAGO*)\n\n3.- Reporte de falla: (Envíe *FALLA*)\n\nPor favor tome nota de otras formas de contacto con *FIBEX TELECOM:* (CableHOGAR)\n\n🖥️ *Para reportar averías:* https://fibextelecom.net/reporte-de-fallas/ o fallas@fibextelecom.net\n\n🧾 *Para reportar pagos:* https://fibextelecom.net/registrar-pago/  o pagoshogar@fibextelecom.net \n\n📲 *Centro de Contactos:* 0212 3108300\n\n🗒️ Para mejorar la calidad de atención, por favor llenar esta pequeña encuesta: https://shorturl.at/cpCGV \n\nPara *#EmpresaName#* ha sido un gusto poder atenderle.\n\n|Gracias por comunicarse con el Centro de Contactos *FIBEX TELECOM*, empresa líder de Venezuela en servicios de TV Cable e Internet hasta su hogar.\n\n Usted podrá realizar múltiples operaciones de forma automática a través de nuestro asesor inteligente Thomas. Elija una opción del siguiente menú:\n\n1.- *Consulta de Saldo:* (Envíe la palabra SALDO separado de su número de Cédula Ej: _SALDO 11222333_)\n\n2.- Reporte de pago: (Envíe *PAGO*)\n\n3.- Reporte de falla: (Envíe *FALLA*)\n\nPor favor tome nota de otras formas de contacto:\n\n🖥️ *Para reportar averías:* fallas@fibextelecom.net\n\n🧾 *Para reportar pagos:* pagoshogar@fibextelecom.net \n\n📲 *Centro de Contactos:* 0212 3108300\n\n🗒️ Para mejorar la calidad de atención, por favor llenar esta pequeña encuesta: https://shorturl.at/cpCGV \n\nPara *FIBEX TELECOM* ha sido un gusto poder atenderle.\n\n}",
                    "smartreply":{
                        "suggestions":["👍","Ha","aevu m?","Okay","Thanks"],
                        "clicktosend":true
                    }
                }
            }
        ]
             
    }
}