export class CamposFakeDb
{
    // Si se edita alguno de los campos aqui presente sobre todo menú
    // asi sea una mayúscula puede dañar el sistema, NO TOCAR MEJOR
    public static campos = {  
        colum: ['title', 'type', 'Lic', 'requerido', 'menú'],
        type: ['string', 'string', 'string', 'boolean', 'menu'],
        TitleColumShow: ['Campo', 'Canal', 'Menú'],
        menu: ["Editar/edit", "Eliminar/delete", "Otra/delete"],
        clientes:[
            {
                id:1,
                campos:[
                    {
                        title:"Cédula",
                        type: "string",
                        requerido: false,
                        Lic: ["04142788259"]
                    },
                    {
                        title:"Email",
                        type: "email",
                        requerido: true,
                        Lic: ["04142788259"]
                    },
                    
                ]
            },
            {
                id:2,
                campos:[
                    {
                        title:"Email",
                        type: "email",
                        requerido: false,
                        Lic: ["04142788259"]
                    },
                ]
            }
        ]
    }
}