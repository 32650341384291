export class AtajosFakeDb
{
    // Si se edita alguno de los campos aqui presente sobre todo menú
    // asi sea una mayúscula puede dañar el sistema, NO TOCAR MEJOR
    public static atajos = {  
        colum: ['abreviado', 'texto', 'Lic', 'menú'],
        type: ['string', 'string', 'string', 'menu'],
        TitleColumShow: ['Abreviado', 'Texto', 'Canal', 'Menú'],
        Variables: ['@name', '@phone', '@saldo', '@contrato', '@ultimopago'],        
        menu: ["Editar/edit", "Eliminar/delete"],
        clientes:[
            {
                id:1,
                atajos:[
                    {
                        abreviado:"/B1",
                        texto: "Este es el texto de B1",
                        Lic: ["04142788259"]
                    },
                    {
                        abreviado:"/B2",
                        texto: "Este es el texto de B2",
                        Lic: ["04142788259"]
                    },
                    
                ]
            },
            {
                id:2,
                atajos:[
                    {
                        abreviado:"/C1",
                        texto: "Este es el texto de C1",
                        Lic: ["04142788259"]
                    },
                    {
                        abreviado:"/C2",
                        texto: "Este es el texto de C2",
                        Lic: ["04142788259"]
                    }
                ]
            }
        ]
    }
}