import { Component } from '@angular/core';

@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent
{
    /**
     * Constructor
     */
    // Debe ser DisableClient si el Total en cola es 0
    // y esta en modo DEvice
    public DisableClient: boolean = true
    constructor()
    {
    }
}
